<template>
  <v-sheet
    v-if="!decodeurs.length"
    :color="`grey`"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      type="text-field, table, data-table"
    >
    </v-skeleton-loader>
  </v-sheet>
  <v-card v-else>
    <v-card-title>
      <span class="primary--text font-weight-bold">Programmation des activations</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-form
      ref="formulaireDec"
    >
      <v-card-text>
        <v-container>
          <!-- bloc pour le nouveau décodeur-->
          <v-row
            class="ml-6 mr-6"
          >
            <v-col
              cols="12"
              md="5"
            >
              <v-autocomplete
                v-model="selectedDecodeur"
                outlined
                dense
                :rules="LocalNameRules"
                :items="decodeursListFormatted"
                :item-text="decoder => decoder.local_name +' - '+decoder.number+' ('+decoder.location+')'"
                item-value="number"
                :menu-props="{ offsetY : true }"
                placeholder="Sélectionnez un décodeur"
                label="Rechercher un décodeur"
                hide-details="auto"
                return-object
                class="mb-3"
              >
                <template v-slot:append>
                  <v-slide-x-reverse-transition
                    mode="out-in"
                  >
                    <v-icon>
                      {{ icons.mdiMagnify }}
                    </v-icon>
                  </v-slide-x-reverse-transition>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="5"
            >
              <v-select
                v-model="selectedOperation"
                outlined
                dense
                :rules="LocalNameRules"
                :items="operation"
                item-text="name"
                item-value="id"
                :menu-props="{ offsetY : true }"
                :readonly="!showSomething"
                placeholder="Sélectionnez une opération"
                label="Sélectionnez une opération"
                hide-details="auto"
                return-object
                class="mb-3"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                @click="addNewDecodeur"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
                Ajouter
              </v-btn>
            </v-col>
          </v-row>

          <v-expansion-panels
            v-model="panel"
            multiple
            focusable
          >
            <v-expansion-panel
              v-for="(decodeur, decodeurIndex) in decodeur"
              :key="decodeurIndex"
              class="mb-4"
            >
              <v-expansion-panel-header class="grey">
                <span class="primary--text text-left"><span class="font-weight-bold">{{
                  decodeur.operation.id === 1 ? 'Réabonnement' : 'Upgrade'
                }}</span><span class="blue"> {{
                  decodeur.local_name + ' - ' + decodeur.number + '('+decodeur.location+')'
                }} </span></span>
                <template #actions>
                  <v-btn
                    text
                    color="error"
                    small
                    @click="removeDecodeur(decodeurIndex)"
                  >
                    <v-icon color="error">
                      {{ icons.mdiCloseCircle }}
                    </v-icon>
                    Supprimer
                    <v-spacer></v-spacer>
                  </v-btn>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-6">
                <v-row class="ml-6 mr-6">
                  <v-col class="col-md-8 col-sm-12">
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="decodeur.number"
                          :readonly="true"
                          label="Numéro du décodeur"
                          outlined
                          dense
                          placeholder="Numéro du décodeur"
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id === 1"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="decodeur.formula"
                          outlined
                          dense
                          :items="formule"
                          item-text="label"
                          item-value="code"
                          :menu-props="{ offsetY : true }"
                          placeholder="Offre sollicité"
                          label="Offre sollicité"
                          hide-details="auto"
                          return-object
                          class="mb-3"
                          @change="getMontant"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id === 2"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="decodeur.formula.label"
                          outlined
                          dense
                          :readonly="decodeur.operation.id === 2"
                          :menu-props="{ offsetY : true }"
                          placeholder="Offre actuelle"
                          label="Offre actuelle"
                          hide-details="auto"
                          class="mb-3"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id == 2"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="decodeur.formula2"
                          outlined
                          dense
                          :items="formuleUpg(decodeur.formula)"
                          item-text="label"
                          item-value="code"
                          :menu-props="{ offsetY : true }"
                          placeholder="Offre sollicité"
                          label="Offre sollicité"
                          hide-details="auto"
                          return-object
                          class="mb-3"
                          @change="getMontant"
                          @input="checkOption(decodeur)"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id === 1"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="decodeur.option"
                          outlined
                          dense
                          :items="option"
                          item-text="nom"
                          item-value="id"
                          :menu-props="{ offsetY : true }"
                          placeholder="Sélectionnez l'option"
                          label="Option"
                          hide-details="auto"
                          return-object
                          class="mb-3"
                          @change="getMontant"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id===1"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="decodeur.Duree"
                          outlined
                          dense
                          :readonly="decodeur.operation.id === 2"
                          :items="Duree"
                          item-text="itemTitle"
                          item-value="id"
                          :menu-props="{ offsetY : true }"
                          placeholder="Durée"
                          label="Durée"
                          hide-details="auto"
                          return-object
                          class="mb-3"
                          @change="getMontant"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id===1"
                        cols="12"
                        md="6"
                      >
                        <v-menu

                          v-model="decodeur.menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Date d'activation"
                              :readonly="true"
                              :value="formatDate(decodeur.activationDate)"
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-slot:append>
                                <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="decodeur.activationDate"
                            :show-current="decodeur.operation.id === 1"
                            :min="decodeur.activationDate"
                            :readonly="decodeur.operation.id === 2"
                            color="primary"
                            locale="pr-fr"
                            @change="refreshStartingTime(decodeur)"
                            @input="decodeur.menu = false,forceRender"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id === 1"
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          v-model="decodeur.menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Date de début"
                              :value="formatDate(decodeur.startTime)"
                              :readonly="true"
                              :disabled="true"
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-slot:append>
                                <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="decodeur.startTime"
                            :readonly="decodeur.operation.id === 2"
                            :disabled="true"
                            color="primary"
                            locale="fr-fr"
                            @input="decodeur.menu = false,forceRender"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id === 2"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="decodeur.option2"
                          outlined
                          dense
                          :items="optionUpg(decodeurIndex)"
                          item-text="nom"
                          item-value="id"
                          :menu-props="{ offsetY : true }"
                          :rules="LocalNameRules"
                          placeholder="Sélectionnez l'option"
                          label="Option"
                          hide-details="auto"
                          return-object
                          class="mb-3"
                          @change="getMontant"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id === 2"
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-model="decodeur.DureeUpg"
                          outlined
                          dense
                          :readonly="decodeur.operation.id === 2"
                          :items="[...decodeur.DureeUpg]"
                          item-text="itemTitle"
                          item-value="id"
                          :menu-props="{ offsetY : true }"
                          placeholder="Durée"
                          label="Durée"
                          hide-details="auto"
                          return-object
                          class="mb-3"
                          @change="getMontant"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-if="decodeur.operation.id===2"
                        cols="12"
                        md="6"
                      >
                        <v-menu

                          v-model="decodeur.menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Date d'activation"
                              :value="formatDate(decodeur.activationDate)"
                              :readonly="true"
                              :disabled="true"
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-slot:append>
                                <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="decodeur.activationDate"
                            :show-current="decodeur.operation.id === 1"
                            :min="currentDate"
                            :readonly="true"
                            :disabled="true"
                            color="primary"
                            locale="fr-fr"
                            @input="decodeur.menu = false,forceRender"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="12"
                        class="d-sm-flex flex-sm-row-reverse pr-0 mt-n8"
                      >
                        <div
                          v-if="decodeur.operation.id === 1"
                          class="d-sm-flex flex-sm-row-reverse align-content-end"
                        >
                          <v-switch
                            :key="decodeur.number"
                            v-model="decodeur.reabo_auto"
                            color="success"
                            inset
                            :readonly="updatePrel"
                            :class="[decodeur.reabo_auto===false?'swicth-custom mt-n1':'', 'mt-n1']"
                            @mousedown="openDialogPrel(decodeur)"
                          >
                          </v-switch>
                          <h3 class="mr-2">
                            <b>Renouvellement automatique ?</b>
                          </h3>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="decodeur.operation.id === 1"
                    class="col-md-4 col-sm-12"
                  >
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Frais Offre :
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <b class="primary--text font-weight-bold">{{
                            withCurrency(decodeur.formula.pu)
                          }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Frais Option:
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <b class="primary--text font-weight-bold">{{
                            withCurrency(decodeur.option.pu)
                          }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Durée :
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <b class="primary--text font-weight-bold">{{
                            decodeur.Duree.itemTitle
                          }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text
                      v-if="remise.active"
                      class="primary--text font-weight-semibold"
                    >
                      Remise : <b>{{ remise.postpaye }}</b>
                    </v-card-text>
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Sous-total:
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right font-weight-bold"
                        >
                          <b class="error--text">{{ withCurrency(decodeur.Montant) }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col
                    v-else
                    class="col-md-4 col-sm-12 text-right"
                  >
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Ancienne Offre:
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <b class="primary--text text-right font-weight-semibold">{{
                            withCurrency(decodeur.formula.pu)
                          }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Nouvelle Offre:
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <b class="primary--text text-right font-weight-semibold">{{
                            withCurrency(decodeur.formula2.pu)
                          }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Frais Option:
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <b class="primary--text text-right font-weight-semibold">{{
                            withCurrency(decodeur.option2.pu)
                          }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Durée:
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <b>{{ decodeur.DureeUpg.itemTitle }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text
                      v-if="remise.active"
                      class="primary--text font-weight-semibold"
                    >
                      Remise : <b>{{ remise.postpaye }}</b>
                    </v-card-text>
                    <v-card-text class="primary--text">
                      <v-row class="m-0">
                        <v-col
                          cols="6"
                          class="text-left"
                        >
                          Sous-total:
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col
                          cols="6"
                          class="text-right font-weight-bold"
                        >
                          <b class="error--text ">{{ withCurrency(decodeur.Montant) }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
        <div class="d-flex align-end flex-row-reverse pa-2">
          <h3>SOLDE DISPONIBLE : <b class="error--text"> {{ withCurrency(showDispenseRestAmount) }}</b></h3>
        </div>
        <v-row>
          <v-col
            cols="6"
            class="p-2"
          >
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left border-left">
                      Taxe
                    </th>
                    <th class="text-left">
                      Base
                    </th>
                    <th class="text-left border-right">
                      Montant
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border">
                    <td class="border-left">
                      Droits d'accises 0%
                    </td>
                    <td class="border-left">
                      {{ withCurrency(taxes.montantSansDroit) }}
                    </td>
                    <td class="border-left border-right">
                      {{ withCurrency(taxes.sansDroit) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="border-left">
                      Droits d'accises 12.50%
                    </td>
                    <td class="border-left">
                      {{ withCurrency(taxes.montantAvecDroit) }}
                    </td>
                    <td class="border-left border-right">
                      {{ withCurrency(taxes.avecDroit) }}
                    </td>
                  </tr>
                  <tr class="border">
                    <td class="border-left">
                      TVA {{ clientInfo.gs_tva }}%
                    </td>
                    <td class="border-left">
                      {{ withCurrency(taxes.montantTva) }}
                    </td>
                    <td class="border-left border-right">
                      {{ withCurrency(taxes.tva) }}
                    </td>
                  </tr>
                  <tr class="border">
                    <td class="border-left border-bottom">
                      <b>Total taxes</b>
                    </td>
                    <td class="border-left border-bottom"></td>
                    <td class="border-left border-right border-bottom">
                      <b>{{ withCurrency(taxes.total) }}</b>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col
            cols="6"
            class="p-2"
          >
            <v-simple-table class="border">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <b>Montant HT</b><br>
                      <!--                    DA <br>-->
                      <!--                    TVA <br>-->
                      <b>Taxes</b>
                    </td>
                    <td>
                      <b>{{ withCurrency(montantFormuleHT+montantOptionHT) }} </b><br>
                      <!--                    {{  withCurrency(taxes.avecDroit) }}<br>-->
                      <!--                    {{  withCurrency(taxes.tva) }}<br>-->
                      <b>{{ withCurrency(taxes.total) }}</b>
                    </td>
                  </tr>

                  <tr>
                    <td>Montant TTC</td>
                    <td v-if="activatedIr">
                      {{ withCurrency(taxes.total + montantFormuleHT+montantOptionHT) }}
                    </td>
                    <td v-else>
                      {{ withCurrency(montant) }}
                    </td>
                  </tr>
                  <tr v-if="activatedIr">
                    <td>
                      IR ({{ clientInfo.gs_taux_ir }}%)<br>
                      TVA retenue à la source <br>
                    </td>
                    <td v-if="activatedIr">
                      {{ withCurrency(taxes.ir) }}<br>
                      {{ withCurrency(taxes.tva) }}<br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Discount({{ bond.bonusActivationParameters }}%)<br>
                      <!--                    TOTAL OPTION-->
                      <h3><b>NET A PAYER</b></h3>
                    </td>
                    <td>
                      {{ withCurrency(Math.round((montantFormuleHT * bond.bonusActivationParameters) / 100 )) }}<br>
                      <!--                    {{ withCurrency(montantOption) }}<br>-->
                      <h3><b class="success--text"> {{ withCurrency(netPayable) }}</b></h3>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <!--        <div class="d-flex align-end flex-row-reverse pa-2">-->
        <!--          <h3>TOTAL TTC (hors discount) : <b class="red"> {{ withCurrency(montantFormule) }}</b></h3>-->
        <!--        </div>-->

        <!--        <div class="d-flex align-end flex-row-reverse pa-2">-->
        <!--          <h3>TVA et Droit d'assise ({{ clientInfo.gs_tva }} % & {{ clientInfo.gs_tva_with_tax }} %) : <b class="red"> {{  withCurrency(taxes.tva) }}</b></h3>-->
        <!--        </div>-->
        <!--        <div class="d-flex align-end flex-row-reverse pa-2">-->
        <!--          <h3>TOTAL HT (hors discount) : <b class="red"> {{ withCurrency(montantFormuleHT) }}</b></h3>-->
        <!--        </div>-->
        <!--        <div class="d-flex align-end flex-row-reverse pa-2">-->
        <!--          <h3>Discount({{ bond.bonusActivationParameters }}%) : <b class="success&#45;&#45;text"> {{ withCurrency(Math.round((montantFormuleHT * bond.bonusActivationParameters) / 100 ))}} </b></h3>-->
        <!--        </div>-->
        <!--&lt;!&ndash;        <div class="d-flex align-end flex-row-reverse pa-2 success&#45;&#45;text">&ndash;&gt;-->
        <!--&lt;!&ndash;          <h3>TOTAL OFFRE HT DISCOUNTE : <b class="success&#45;&#45;text"> {{ withCurrency(netPayable-montantOption) }}</b></h3>&ndash;&gt;-->
        <!--&lt;!&ndash;        </div>&ndash;&gt;-->
        <!--        <div class="d-flex align-end flex-row-reverse pa-2">-->
        <!--          <h3>TOTAL OPTION : <b class="red"> {{ withCurrency(montantOption) }}</b></h3>-->
        <!--        </div>-->
        <!--        <div class="d-flex align-end flex-row-reverse pa-2 success&#45;&#45;text">-->
        <!--          <h3>NET A PAYER : <b class="success&#45;&#45;text"> {{ withCurrency(netPayable) }}</b></h3>-->
        <!--        </div>-->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          :disabled="decodeur.length === 0 || loading"
          @click="resetPage()"
        >
          Annuler
        </v-btn>
        <v-btn
          v-if="isVisu()"
          color="primary"
          :disabled="decodeur.length === 0 || loading"
          @click="checkUpgrade"
        >
          Programmer
        </v-btn>
      </v-card-actions>
    </v-form>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :vertical="vertical"
        :timeout="3000"
        :color="colorSnack"
        top
        right
        transition="scale-transition"
      >
        {{ textSnack }}
      </v-snackbar>
    </div>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="760px"
    >
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <span class="text-h5">Votre <b>Upgrade</b> <span class="error--text">ne change pas la date de fin d'abonnement en cours</span>. Si vous le confirmez, veuillez <b
                  class="success--text"
                >«valider»</b> sinon <b class="error--text">«rejeter»</b></span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                color="error"
              >
                <v-data-table
                  :headers="headersDialog"
                  :items="upgradeItems"
                  sort-by="id"
                  class="table-kitchen-sink"
                >
                  <template #[`item.decodeur`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span class="d-block font-weight-semibold text-truncate text--primary">{{
                          item.location
                        }} - {{ item.number }}</span>
                      </div>
                    </div>
                  </template>
                  <template #[`item.validite`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <span
                          class="d-block font-weight-semibold text-truncate success--text font-weight-bold"
                        >{{
                          item.validityDate
                        }} jours</span>
                      </div>
                    </div>
                  </template>
                  <template #[`item.actions`]="{item}">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column ms-3">
                        <v-btn
                          x-small
                          color="error"
                          @click="removeItemFromUpgrade(item)"
                        >
                          retirer de la liste
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDialog"
          >
            Rejeter
          </v-btn>
          <v-btn
            color="primary"
            :disabled="itemsToValidate.length === 0"
            @click="programOperation"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPrel"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          <h4 class="text-decoration-underline text-center">
            CONDITIONS GENERALES DU SERVICE DE
            PRÉLÈVEMENT AUTOMATIQUE
          </h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-justify primary--text">
          <p class="text-body-1">
            Le prélèvement automatique est la solution idéale pour vous permettre d’éviter la perte
            de vos images, de renouveler vos abonnements sans tracasseries, et surtout bénéficier de
            la semaine généreuse ou tous autres avantages liés au payement avant échéance.
          </p>
          <p class="text-body-1">
            L’acceptation du présent contrat emporte souscription au paiement automatique des frais
            d’abonnement aux offres CANAL+, ainsi que l’acceptation des conditions générales du
            service ci-après.
          </p>
          <p class="text-body-1">
            Le montant des abonnements indiqués sera automatiquement prélevé de votre compte selon
            le moyen de paiement sélectionné et modifiable mensuellement.
          </p>
          <p class="text-body-1">
            Le montant à prélever correspond à celui des abonnements en cours, sans aucun frais
            supplémentaire.
          </p>
          <p class="text-body-1">
            Les prélèvements pourront être faits à partir du cinquième jour avant la fin de(s)
            abonnement(s) en cours.
          </p>
          <p class="text-body-1">
            En cas d’absence de fonds sur votre compte pendant toute la période indiquée ci-dessus,
            le défaut de paiement sera acté et celui-ci sera suspendu à la date de fin d’abonnement.
            Il sera néanmoins possible d’effectuer un prélèvement durant les cinq jours suivant
            l’échéance, si les fonds disponibles sur le compte le permettent.
            Le souscripteur sera instantanément notifié de tout prélèvement.
          </p>
          <p class="text-body-1">
            Bien vouloir cliquer sur <span class="success--text font-weight-bold text-lg-h6">« J’accepte »</span> pour
            le valider sinon sur <span class="error--text font-weight-bold text-lg-h6">« Je renonce »</span>.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="error"
            outlined
            @click="refusePrel"
          >
            Je renonce
          </v-btn>
          <v-btn
            v-if="isVisu()"
            color="primary"
            @click="acceptPrel"
          >
            J’accepte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPlusCircle, mdiSendOutline, mdiCloseCircle, mdiPlus, mdiCalendarMonth, mdiMagnify,
} from '@mdi/js'
import {
  ref, onMounted, computed, nextTick, onBeforeMount, reactive, watch,
} from '@vue/composition-api'
import Http from '@/helpers/http'
import moment from 'moment/moment'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { useRouter } from '@core/utils'
import { PREPAYE_TYPE } from '@/helpers/constants/contrat'
import Vue from 'vue'
import useDynamicConstant from '@/helpers/useDynamicConstant'
import { isVisu } from '@/helpers/constants/roles'

export default {
  props: {
    decoders: {
      type: Array,
      required: false,
    },
    operationCheck: {
      type: [Number, String],
      required: false,
    },
  },
  setup(props) {
    const decodeurs = ref([])

    const snackbar = ref(false)
    const vertical = true
    const textSnack = ref('')
    const colorSnack = ref('error')

    const selectedDecodeur = ref({})
    const selectedOperation = ref({})
    let decodeur = reactive([])
    const currentDate = moment(new Date()).format('YYYY-MM-DD')

    // const decodeur = router.currentRoute.params.decodeur
    // console.log("decodeur", router.currentRoute.params)
    const index = ref(0)
    const formule = ref([{}])
    const operation = ref([{}])
    const montantFormule = ref(0)
    const montantFormuleHT = ref(0)
    const montantOptionHT = ref(0)
    const montantOption = ref(0)
    const taxes = ref({
      sansDroit: 0,
      avecDroit: 0,
      montantSansDroit: 0,
      montantAvecDroit: 0,
      montantTva: 0,
      tva: 0,
      ir: 0,
      total: 0,
    })
    const { route } = useRouter()

    const option = ref([{
      alpha2: 'CM',
      code: '',
      id: 0,
      nom: 'Aucune',
      pu: 0,
    }])

    const loadFormules = () => {
      Http.get('get-formules')
        .then(response => {
          formule.value = response.data

          // console.log('chargement des formules terminées')
          // console.log(formule.value)
          // eslint-disable-next-line no-use-before-define
          loadOptions()
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }
    const isPrepaye = computed(() => PREPAYE_TYPE === JSON.parse(localStorage.getItem('defaultContrat')).customer_type_id)

    const bond = ref({ bonusActivationParameters: 0 })
    const amountCanDep = ref(0)

    const getDeposit = () => {
      Http.get(`get-deposit-payment-information/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          if (response.status === 200) {
            bond.value = response.data
          }
        })
        .catch(error => { console.log(error) })
    }

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = [...option.value, ...response.data]

          // console.log('chargement des options terminés')
          // console.log(option.value)

          if (props.decoders !== undefined) {
            isSending.value = true
            // eslint-disable-next-line no-use-before-define
            loadDecodersSend(props.operationCheck, props.decoders)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const canContinueDispense = computed(() => netPayable.value <= amountCanDep.value)
    // eslint-disable-next-line no-restricted-globals,no-use-before-define
    const showDispenseRestAmount = computed(() => (isNaN(netPayable.value) ? amountCanDep.value : (amountCanDep.value - netPayable.value)))

    const loadAmountCanDispense = () => {
      Http.get(`spendable-amount/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          console.log('get-amount-can-dep')
          amountCanDep.value = parseFloat(response.data)
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const loadOperations = () => {
      Http.get('request-type')
        .then(response => {
          operation.value = response.data
          loadFormules()
          loadAmountCanDispense()
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    // const loadDecodeurs = () => {
    //   const user = JSON.parse(Session.get('userData'))
    //   console.log(user)
    //   const role = Session.get('role')
    //   decodeurs.value = []
    //
    //   user.contrats.forEach(item => {
    //     const parameters = { id: user.id, roleId: role, contractId: item.corporate_segment_id }
    //     console.log(parameters)
    //
    //     Http.post('get-decoders', parameters)
    //       // eslint-disable-next-line no-return-assign
    //       .then(response => {
    //         if (response.data.length !== 0) decodeurs.value.push(response.data)
    //
    //         console.log('chargement des decodeurs terminés')
    //         console.log(decodeurs.value)
    //       })
    //       .catch(error => {
    //         console.log(error)
    //       })
    //   })
    // }
    // const user = JSON.parse(Session.get('userData'))
    // const contrat = JSON.parse(localStorage.getItem('defaultContrat'))
    // const role = JSON.parse(localStorage.getItem('defaultRole'))

    const loadDecodeurs = () => {
      // const parameters = { id: user.id, roleId: role.id, contractId: contrat.id }
      // console.log(parameters)

      Http.get(`get-decoders/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        // eslint-disable-next-line no-return-assign
        .then(response => {
          decodeurs.value = response.data

          // console.log('chargement des decodeurs terminés')
          decodeurs.value.sort((a, b) => {
            // eslint-disable-next-line prefer-const
            let fa = a.location.toLowerCase(); const
              fb = b.location.toLowerCase()
            if (fa < fb) {
              return -1
            }
            if (fa > fb) {
              return 1
            }

            return 0
          })

          // console.log('chargement des decodeurs terminés')
          // console.log(decodeurs.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    const isSending = ref(false)
    onBeforeMount(() => {
      loadDecodeurs()

      // if (!isPrepaye.value) {
      getDeposit()

      // }
    })

    const loadDecodersSend = (operationD, decoders) => {
      // eslint-disable-next-line radix
      // const operationProps = { id: parseInt(operationD), name: parseInt(operationD) === 1 ? 'Réabonnement' : 'Upgrade' }
      let i = 0
      for (i = 0; i < decoders.length; i++) {
        // eslint-disable-next-line prefer-destructuring
        selectedDecodeur.value = decodeurs.value.filter(el => el.id === decoders[i].id)[0]
        // eslint-disable-next-line radix,prefer-destructuring
        // console.log('selectedOperation.value')
        // eslint-disable-next-line prefer-destructuring,radix
        selectedOperation.value = operation.value.filter(el => el.id === parseInt(operationD))[0]

        // console.log(operationD)
        // console.log(selectedOperation.value)
        // eslint-disable-next-line no-use-before-define
        addNewDecodeur()
      }

      isSending.value = false
    }

    onMounted(() => {

    })

    // eslint-disable-next-line array-callback-return
    const formuleUpg = decoder => formule.value.filter(i => parseFloat(i.pu) >= parseFloat(decoder.pu))

    const Duree = computed(() => {
      const tab = []
      const moisTab = [1, 3, 6, 12, 24]
      const m = 'mois'
      let i = 0
      // eslint-disable-next-line no-plusplus
      while (i < moisTab.length) {
        tab.push({ id: moisTab[i], itemTitle: `${moisTab[i]} ${m}` })
        // eslint-disable-next-line no-plusplus
        i++
      }

      return tab
    })

    const clientInfo = JSON.parse(localStorage.getItem('clientInfo'))

    console.log('cleintInfo', clientInfo)

    const currency = clientInfo.devise

    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const remise = ref({
      active: false,
    })

    const operationFormat = computed(() => {
      const reaboTab = []
      const upgradeTab = []
      decodeur.forEach(item => {
        if (item.operation.id === 1) {
          reaboTab.push({
            decoderId: item.id,
            activationDate: item.activationDate,
            formula1: item.formula.code,
            isTaxSubject1: item.formula.is_tax_subject,
            option1: item.option.code,
            // eslint-disable-next-line radix
            amount_formula: parseInt(item.formula.pu),
            // eslint-disable-next-line radix
            amount_option: parseInt(item.option.pu),
            // eslint-disable-next-line radix
            // amount: parseInt(item.Montant),
            // eslint-disable-next-line radix
            duration: parseInt(item.Duree.id),
          })
        } else if (item.operation.id === 2) {
          upgradeTab.push({
            decoderId: item.id,
            activationDate: item.activationDate,
            formula1: item.formula.code,
            isTaxSubject1: item.formula.is_tax_subject,
            formula2: item.formula2.code,
            isTaxSubject2: item.formula2.is_tax_subject,
            option1: item.option.code,
            option2: item.option2.code,
            // eslint-disable-next-line radix
            // amount: parseInt(item.Montant),
            // eslint-disable-next-line radix
            amount_formula1: parseInt(item.formula.pu),
            // eslint-disable-next-line radix
            // amount_option1: parseInt(item.option.pu),
            // eslint-disable-next-line radix
            amount_formula2: parseInt(item.formula2.pu),
            // eslint-disable-next-line radix
            amount_option2: parseInt(item.option2.pu),
            // eslint-disable-next-line radix
            duration: parseInt(item.DureeUpg.id),
          })
        }
      })

      return {
        // eslint-disable-next-line radix
        customerSegmentId: JSON.parse(localStorage.getItem('defaultContrat')).id,
        reabo: reaboTab,
        upgrade: upgradeTab,
      }
    })

    const withCurrency = price => `${Number(price).toLocaleString()} ${currency}`

    const formulaireDec = ref(null)

    const showSomething = ref(true)

    const month = 30

    const decodeurUnselected = computed(() => decodeurs.filter(item => item.number !== 0))

    const decodeursListFormatted = computed(() => {
      decodeurs.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign,no-shadow
        // item.formula = formule.value.filter(i => i.code === item.formula).length === 0 ? formule.value.filter((i, index) => index === 2)[0] : formule.value.filter(i => i.code === item.formula)
        item.formulaFormated = formule.value.filter(i => i.code === item.formula).length === 0 ? formule.value.filter((i, index) => index === 2)[0] : formule.value.filter(i => i.code === item.formula)[0]
        // eslint-disable-next-line no-param-reassign,no-shadow
        // item.option = option.value.filter(i => i.code === item.option).length === 0 ? option.value.filter((i, index) => index === 0)[0] : option.value.filter(i => i.code === item.option)
        item.optionFormated = option.value.filter(i => i.code === item.option).length === 0 ? option.value.filter((i, index) => index === 0)[0] : option.value.filter(i => i.code === item.option)[0]
        // eslint-disable-next-line no-param-reassign,no-shadow,prefer-destructuring
        item.Duree = Duree.value[0]
        const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
        const endTime = moment(item.end_subscription).format('YYYY-MM-DD')

        if (moment(today).diff(endTime, 'days') < 0) {
          // eslint-disable-next-line no-param-reassign,no-shadow
          item.startTime = moment().format('YYYY-MM-DD')
        } else if (moment(today).diff(endTime, 'days') < 0) {
          // eslint-disable-next-line no-param-reassign,no-shadow
          item.startTime = moment(moment().format('YYYY-MM-DD')).add(1, 'days')
        } else {
          // eslint-disable-next-line no-param-reassign,no-shadow
          item.startTime = moment(endTime).add(1, 'days').format('YYYY-MM-DD')
        }
        // eslint-disable-next-line no-param-reassign,no-shadow
        item.activationDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')

        // eslint-disable-next-line no-param-reassign,no-shadow
        // item.formula2 = formule.value.filter(i => i.code === item.formula).length === 0 ? formule.value.filter((i, index) => index === 2)[0] : formule.value.filter(i => i.code === item.formula)
        item.formula2 = item.formulaFormated
        // eslint-disable-next-line no-param-reassign,no-shadow
        item.option2 = item.optionFormated

        // eslint-disable-next-line no-param-reassign
        item.reabo_auto = item.is_auto === 1
        // eslint-disable-next-line no-use-before-define

        // const remainingTime = Math.ceil(moment.duration(moment(endTime).diff(moment(today), 'days')).asMonths())
        const diffDays = moment(endTime).diff(moment(today), 'days')
        const remainingTime = (diffDays > 0) ? Math.ceil(diffDays / month) : 0
        // eslint-disable-next-line no-param-reassign
        item.DureeUpg = { id: remainingTime, itemTitle: `${remainingTime} mois` }
      })

      return decodeurs.value
    })

    const forceRender = () => {
      showSomething.value = false
      nextTick(() => {
        // Okay, now that everything is destroyed, lets build it up again
        // eslint-disable-next-line no-plusplus
        showSomething.value = true
      })
    }
    const loading = ref(false)

    const formatDate = itemDate => moment(itemDate, 'YYYY-MM-DD').format('DD/MM/YYYY')

    const refreshStartingTime = item => {
      const activationTime = moment(item.activationDate).format('YYYY-MM-DD')
      const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
      const endTime = moment(item.end_subscription).format('YYYY-MM-DD')

      if (moment(activationTime).diff(endTime, 'days') > 0) {
        // eslint-disable-next-line no-param-reassign,no-shadow
        item.startTime = moment(activationTime).add(1, 'days').format('YYYY-MM-DD')
      } else if (moment(activationTime).diff(endTime, 'days') === 0) {
        // eslint-disable-next-line no-param-reassign,no-shadow
        item.startTime = moment(today).add(1, 'days').format('YYYY-MM-DD')
      } else {
        // eslint-disable-next-line no-param-reassign,no-shadow
        item.startTime = moment(endTime).add(1, 'days').format('YYYY-MM-DD')
      }

      forceRender()
    }
    // eslint-disable-next-line array-callback-return,consistent-return
    const optionUpg = decoderIndex => {
      const optionsUpg = option.value.filter(i => {
        // eslint-disable-next-line no-self-compare
        if (decodeur[decoderIndex].formula.code === decodeur[decoderIndex].formula2.code) {
          return parseFloat(i.pu) > 0
        }

        return i
      })

      // eslint-disable-next-line prefer-destructuring
      // decodeur[decoderIndex].option2 = optionsUpg[0]

      return optionsUpg
    }

    const refreshEndDate = decoder => {
      // eslint-disable-next-line radix,no-param-reassign
      const start = new Date(decoder.start_subscription).toISOString()
      // eslint-disable-next-line no-param-reassign
      decoder.end_subscription = moment(moment(start, 'YYYY-MM-DD').add((month * decoder.Duree.id), 'days').toDate().toISOString()).format('YYYY-MM-DD')
      forceRender()
    }

    // eslint-disable-next-line no-shadow,radix
    const sumAmountReabo = (formulePrice, optionPrice, Duree) => (parseFloat(formulePrice) + parseFloat(optionPrice)) * parseInt(Duree)

    // eslint-disable-next-line no-shadow
    const sumAmountUpgrade = (formulePrice2, formulePrice, optionPrice, Duree) => {
      // eslint-disable-next-line no-unused-expressions,radix
      if (formulePrice !== formulePrice2) {
        // eslint-disable-next-line radix
        return ((parseFloat(formulePrice2 - formulePrice) + parseFloat(optionPrice)) * parseInt(Duree))
      }

      // eslint-disable-next-line radix,
      return parseFloat(optionPrice) * parseInt(Duree)
    }

    const displaySnack = texte => {
      textSnack.value = texte
    }

    const programOperation = () => {
      // eslint-disable-next-line no-use-before-define
      dialog.value = false
      // eslint-disable-next-line no-use-before-define
      loading.value = true
      const params = operationFormat.value

      console.log(params)
      Http.post('service-request', params)
        .then(response => {
          // eslint-disable-next-line no-use-before-define
          if (response.status === 201) {
            // eslint-disable-next-line no-use-before-define
            router.push({ name: 'list-operation' })
          }
        })
        .catch(error => {
          loading.value = false
          console.log(error.response)
        })
    }

    // data
    const panel = reactive([])
    const montant = ref(0)

    const { htValue } = useDynamicConstant()

    const activatedIr = JSON.parse(localStorage.getItem('customer')).withholding_ir

    const getMontant = () => {
      montant.value = 0
      montantFormule.value = 0
      montantFormuleHT.value = 0
      montantOptionHT.value = 0
      montantOption.value = 0
      taxes.value = {
        sansDroit: 0,
        avecDroit: 0,
        montantSansDroit: 0,
        montantAvecDroit: 0,
        montantTva: 0,
        tva: 0,
        ir: 0,
        total: 0,
      }
      decodeur.forEach(item => {
        // eslint-disable-next-line radix,no-param-reassign,no-unused-expressions
        if (item.operation.id === 1) {
          // eslint-disable-next-line no-param-reassign,radix
          item.Montant = (parseInt(item.formula.pu) + parseInt(item.option.pu)) * parseInt(item.Duree.id)

          console.log(item.formula)

          const mtn = parseInt(item.formula.pu) * parseInt(item.Duree.id)
          montantFormule.value += mtn

          const mtnOption = parseInt(item.option.pu) * parseInt(item.Duree.id)
          montantOption.value += parseInt(item.option.pu) * parseInt(item.Duree.id)

          console.log('params', mtn, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          const ht = htValue(mtn, item.formula.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)
          const htOption = htValue(mtnOption, 0, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          montantFormuleHT.value += ht
          montantOptionHT.value += htOption

          if (parseInt(item.formula.is_tax_subject)) {
            taxes.value.montantAvecDroit += ht
            taxes.value.avecDroit += Math.round((ht * parseFloat(clientInfo.gs_taux_droit_assise)) / 100)
          } else {
            taxes.value.montantSansDroit += ht
          }

          taxes.value.montantSansDroit += htOption

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva * clientInfo.gs_tva) / 100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

          if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantOptionHT.value + montantFormuleHT.value) * parseFloat(clientInfo.gs_taux_ir)) / 100)

          // taxes.value.ir = Math.round((netPayable.value*2.2)/100)

          console.log('tva', taxes.value)
          console.log('mtn', mtn)
          console.log('ht', ht)
        } else {
          // eslint-disable-next-line no-param-reassign,radix
          // eslint-disable-next-line no-param-reassign
          item.Montant = sumAmountUpgrade(item.formula2.pu, item.formula.pu, item.option2.pu, item.DureeUpg.id)

          console.log('element', item)

          // let mtnHt = ((parseFloat(ht2 - ht1)) * parseInt(item.DureeUpg.id))
          const mtnTTC = ((parseFloat(item.formula2.pu - item.formula.pu)) * parseInt(item.DureeUpg.id))

          const mtnHt = htValue(mtnTTC, item.formula2.is_tax_subject, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          const mtnOption = parseFloat(item.option2.pu) * parseInt(item.Duree.id)

          const mtnOptionHt = htValue(mtnOption, 0, clientInfo.gs_tva, clientInfo.gs_has_orther_tax, clientInfo.gs_tva_with_tax, activatedIr, clientInfo.gs_taux_ir, clientInfo.gs_taux_droit_assise)

          montantFormule.value += mtnTTC

          montantFormuleHT.value += mtnHt

          montantOptionHT.value += mtnOptionHt

          if (parseInt(item.formula2.is_tax_subject)) {
            taxes.value.montantAvecDroit += mtnHt
            taxes.value.avecDroit += Math.round((mtnHt * parseFloat(clientInfo.gs_taux_droit_assise)) / 100)
          } else {
            taxes.value.montantSansDroit += mtnHt
          }

          taxes.value.montantSansDroit += mtnOptionHt

          taxes.value.montantTva = taxes.value.montantAvecDroit + taxes.value.avecDroit + taxes.value.montantSansDroit
          taxes.value.tva = Math.round((taxes.value.montantTva * clientInfo.gs_tva) / 100)

          taxes.value.total = taxes.value.tva + taxes.value.avecDroit

          if (parseInt(activatedIr)) taxes.value.ir = Math.round(((montantOptionHT.value + montantFormuleHT.value + taxes.value.total) * parseFloat(clientInfo.gs_taux_ir)) / 100)

          // taxes.value.ir = Math.round((netPayable.value*2.2)/100)

          console.log('tva', taxes.value)

          // tva.value  = tva.value + (mtnTTC - mtnHt)

          montantOption.value += parseFloat(item.option2.pu) * parseInt(item.Duree.id)
        }

        // // refreshEndDate(item)
        // if (remise.value.active) {
        //   // eslint-disable-next-line radix
        //   montant.value += (parseInt(item.Montant) - ((parseInt(item.Montant) * 0.03)))
        // } else {
        // eslint-disable-next-line radix
        montant.value += (parseInt(item.Montant))

        // }
      })

      console.log('montantFormule.value', montantFormule.value)
      console.log('montantFormuleHT.value', montantFormuleHT.value)
      console.log('montantOptionHT.value', montantOptionHT.value)
      console.log('montantOption.value', montantOption.value)
      console.log('montant.value', montant.value)

      forceRender()
    }

    const removeDecodeur = decodeurIndex => {
      // eslint-disable-next-line no-plusplus
      index.value--

      panel.splice(panel.indexOf(decodeurIndex), 1)

      // console.log('decodeurIndex', decodeurIndex)
      // console.log('this.panel', panel)

      decodeur.splice(decodeurIndex, 1)

      getMontant()
    }

    const itemsToValidate = ref([])
    const dialog = ref(false)
    const hasOpUpgrade = ref(0)
    const indexItems = ref([])

    const upgradeItems = computed(() => itemsToValidate.value)

    const checkUpgrade = () => {
      if (!canContinueDispense.value && !isPrepaye) {
        snackbar.value = false
        textSnack.value = 'Le montant de cette programmation est supérieure à celle que vous pouvez dépenser, veuillez réajuster cette programmation'
        colorSnack.value = 'error'
        snackbar.value = true
      } else {
        loading.value = true
        if (hasOpUpgrade.value) {
          // eslint-disable-next-line array-callback-return,consistent-return
          const today = moment(new Date()).format('YYYY-MM-DD')
          // eslint-disable-next-line array-callback-return,consistent-return
          itemsToValidate.value = decodeur.filter(i => {
            if (i.operation.id === 2) {
              // eslint-disable-next-line no-param-reassign
              i.decodeurIndex = decodeur.indexOf(i)
              // eslint-disable-next-line no-param-reassign
              i.validityDate = moment(i.end_subscription).diff(moment(today), 'days')

              return i
            }
          })

          // console.log('itemsToValidate')
          // console.log(itemsToValidate.value)

          dialog.value = true
        } else {
          programOperation()
        }
      }
    }

    const closeDialog = () => {
      loading.value = false
      dialog.value = false
    }

    const removeItemFromUpgrade = item => {
      removeDecodeur(item.decodeurIndex)
      // eslint-disable-next-line no-plusplus
      hasOpUpgrade.value--
      itemsToValidate.value.splice(itemsToValidate.value.indexOf(item), 1)
    }

    const checkOption = item => {
      if (item.formula.code === item.formula2.code) {
        if (item.option2.pu === 0) {
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          item.option2 = option.value[1]
        }
      }
    }

    const addNewDecodeur = () => {
      snackbar.value = false

      if ((Object.keys(selectedDecodeur.value).length !== 0 && Object.keys(selectedOperation.value).length !== 0) || isSending.value) {
        const find = decodeur.find(d => d.number === selectedDecodeur.value.number)

        if (Object.keys(decodeur).length === 0 || find === undefined || isSending.value) {
          const endTime = moment(selectedDecodeur.value.end_subscription).format('YYYY-MM-DD')
          const today = moment(new Date()).format('YYYY-MM-DD')
          if (((moment(endTime).diff(moment(today), 'days') > 0) && selectedOperation.value.id === 2) || selectedOperation.value.id === 1) {
            panel.push(index.value)
            // eslint-disable-next-line no-plusplus
            index.value++
            const operationSelectionne = {
              id: selectedOperation.value.id,
              name: selectedOperation.value.name,
            }

            let amount = 0
            if (operationSelectionne.id === 1) {
              // console.log(selectedDecodeur.value.formulaFormated.pu)
              amount += sumAmountReabo(selectedDecodeur.value.formulaFormated.pu, selectedDecodeur.value.DureeUpg.id, selectedDecodeur.value.Duree.id)
              refreshStartingTime(selectedDecodeur.value)
            } else if (operationSelectionne.id === 2) {
              // eslint-disable-next-line no-plusplus
              hasOpUpgrade.value++
              amount += sumAmountUpgrade(selectedDecodeur.value.formulaFormated.pu, selectedDecodeur.value.formulaFormated.pu, option.value[1].pu, selectedDecodeur.value.DureeUpg.id)
            }

            decodeur.push(
              {
                id: selectedDecodeur.value.id,
                number: selectedDecodeur.value.number,
                location: selectedDecodeur.value.location,
                local_name: selectedDecodeur.value.local_name,
                formula: selectedDecodeur.value.formulaFormated,
                option: selectedDecodeur.value.optionFormated,
                start_subscription: selectedDecodeur.value.start_subscription,
                end_subscription: selectedDecodeur.value.end_subscription,
                menu: selectedDecodeur.value.menu,
                menu2: selectedDecodeur.value.menu2,
                Duree: selectedDecodeur.value.Duree,
                DureeUpg: selectedDecodeur.value.DureeUpg,
                operation: operationSelectionne,
                startTime: selectedDecodeur.value.startTime,
                decoderId: selectedDecodeur.value.id,
                activationDate: selectedDecodeur.value.activationDate,
                formula2: selectedDecodeur.value.formulaFormated,
                option2: option.value[1],
                is_auto: selectedDecodeur.value.is_auto,
                reabo_auto: selectedDecodeur.value.reabo_auto,
                Montant: amount,
              },
            )
            getMontant()
          } else {
            displaySnack('L\'abonnement de ce décodeur est échu. Veuillez effectuer un réabonnement pour celui-ci')
            snackbar.value = true
          }
        } else {
          displaySnack('Ce décodeur a déjà été choisi')
          snackbar.value = true
        }
      } else {
        displaySnack("Veuillez sélectionner le Décodeur et le type d'opération avant de valider.")
        snackbar.value = true
      }
    }

    const dialogPrel = ref(false)
    const prelItem = ref({})
    const updatePrel = ref(true)

    const openDialogPrel = item => {
      prelItem.value = item
      dialogPrel.value = true
    }

    const reaboAutomatique = item => {
      snackbar.value = false
      const autoValue = item.reabo_auto ? 0 : 1
      // eslint-disable-next-line no-param-reassign
      // item.is_auto = !item.is_auto
      // eslint-disable-next-line no-unreachable
      const params = { decoderId: item.id, isAutoValue: autoValue }
      const dItem = ref(decodeur.filter(i => i.id === prelItem.value.id)[0])
      Http.post('isAuto', params)
        .then(response => {
          colorSnack.value = 'success'
          textSnack.value = 'Activation effectuée avec succès'
          snackbar.value = true
          console.log('dItem')
          Vue.set(dItem.value, 'reabo_auto', !dItem.value.reabo_auto)
          Vue.set(dItem.value, 'is_auto', ((dItem.value.reabo_auto) ? 1 : 0))
        })
        .catch(error => {
          // eslint-disable-next-line no-unused-expressions
          console.log(error.data)
        })
    }

    const closeDialogPrel = () => {
      nextTick(() => {
        prelItem.value = {}
        updatePrel.value = true
        dialogPrel.value = false
      })
    }
    const acceptPrel = () => {
      updatePrel.value = false
      reaboAutomatique(prelItem.value)
      dialogPrel.value = false
      closeDialogPrel()
    }

    const refusePrel = () => {
      prelItem.value = {}
      closeDialogPrel()
    }

    const netPayable = computed(() => montantFormule.value - Math.round((montantFormuleHT.value * bond.value.bonusActivationParameters) / 100) + montantOption.value)

    const resetPage = () => {
      window.location.reload()
    }

    watch(decodeur, (newValue, oldValue) => {
      console.log('newValue')
      console.log(newValue)
      decodeur = newValue
    })

    return {
      isVisu,
      decodeur,
      decodeurs,
      formule,
      operation,
      Duree,
      option,
      isPrepaye,
      getDeposit,
      netPayable,
      index,
      selectedDecodeur,
      selectedOperation,
      LocalNameRules,
      formulaireDec,
      montant,
      loadFormules,
      loadOptions,
      loadOperations,
      decodeurUnselected,
      decodeursListFormatted,

      loadDecodersSend,
      loadDecodeurs,
      withCurrency,
      remise,
      refreshEndDate,
      operationFormat,
      showSomething,
      forceRender,
      programOperation,
      sumAmountReabo,
      sumAmountUpgrade,
      formuleUpg,
      optionUpg,
      getMontant,
      refreshStartingTime,
      formatDate,
      currentDate,
      checkOption,
      loading,
      addNewDecodeur,
      resetPage,
      bond,
      montantFormule,
      montantFormuleHT,
      montantOptionHT,
      taxes,
      montantOption,

      showDispenseRestAmount,
      hasOpUpgrade,
      checkUpgrade,
      dialog,
      indexItems,
      itemsToValidate,
      headersDialog: [
        { text: 'Decodeur', value: 'decodeur' },
        { text: 'Nombre de jour de validité', value: 'validite' },
        { text: 'Action', value: 'actions', sortable: false },
      ],
      upgradeItems,
      removeItemFromUpgrade,
      removeDecodeur,
      closeDialog,

      panel,
      snackbar,
      vertical,
      textSnack,
      displaySnack,
      colorSnack,
      route,
      isSending,
      activatedIr,

      reaboAutomatique,
      dialogPrel,
      prelItem,
      updatePrel,
      openDialogPrel,
      closeDialogPrel,
      acceptPrel,
      refusePrel,
      clientInfo,

      // Icons
      icons: {
        mdiSendOutline,
        mdiCloseCircle,
        mdiPlusCircle,
        mdiPlus,
        mdiCalendarMonth,
        mdiMagnify,
      },
    }
  },
  created() {
    this.loadOperations()
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
//.grey{
//  background: rgba(232, 232, 232, 0.52);
//}

.text-black {
  color: black;
}

.border{
  border: 1px solid black;
}
.border-left{
  border-left: 1px solid #d1d1d1;
}
.border-right{
  border-right: 1px solid #d1d1d1;
}

.border-bottom{
  border-bottom: 1px solid #d1d1d1;
}

</style>
